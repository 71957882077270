import * as React from "react"
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import localize from "../components/localize";
import MyPortableText from "../components/MyPortableText";
import CallToAction from "../components/CallToAction";
import {useEffect, useRef} from "react";
import {localeLink} from "../util/index";
import {GatsbyImage} from "gatsby-plugin-image";

export const query = graphql`
query($slug: String){
sanityAngebote(slug: {current: {eq: $slug}}) {
          icon{ asset {gatsbyImageData}}
    title{
    _type
    en
    de
    }
    cooperation_logo { asset {gatsbyImageData}}
    back{
    en
    de
    _type}
    _rawText
    _rawSubheadline
    subheadline_unterseite{
    en
    de
    _type
    }
    
    _rawCtatext
        ctalink1text{
            en
            de
            _type
        }
        ctalink1
        cooperation_link
        ctalink2text{
            en
            de
            _type
        }
        ctalink2
  }
  site {
    siteMetadata {
    lang{
        _type
        en
        de
        }
        angebot{
        _type
        en
        de
        }
        kunden{
        _type
        en
        de
        }
        impressum{
        _type
        en
        de
        }
        datenschutz{
        _type
        en
        de
        }
        agbs{
        _type
        en
        de
        }
        telefon{
        _type
        en
        de
        }
        startseite{
        _type
        en
        de
        }
    }
    }   
    allSanitySettings {
    edges {
      node {
      standorte {
          street
          plz
          _rawStadt
          _rawName
        }
        telefon
        skype
        email
        jobsexternal
        jobsexternallink
     }
     }
    }
    allSanityNetzwerk {
    edges {
      node {
      title{
      en
      de
      _type
      }
      }
      }
      }
}
    `;

const AngebotPage = ({data, location}) => {

    const page = data.sanityAngebote;
    const settings = data.allSanitySettings.edges[0].node;
    const netzwerk = data.allSanityNetzwerk.edges[0].node;

    let titleRef = useRef();

    useEffect(() => {
        titleRef.current.innerHTML = page.title;
    })

    return (
        <div id={"top"}>
            <Layout location={location} metadata={data.site.siteMetadata} settings={settings} netzwerk={netzwerk.title}>
                <SEO title={page.title.replace('&shy;', '') + " | Dr. Barbara Stampf"}/>
                <div className={"angebot__outer"}>
                    <div className="color-bg bg-green left3"></div>
                    <div className="color-bg bg-blue right3"></div>
                    <div className="angebot__inner">
                        <div className="title">
                            <div className={"title__wrapper"}>
                                <GatsbyImage className={"angebot__icon__sub"} image={page.icon?.asset?.gatsbyImageData} alt={page._rawTitle}/>
                                <h1 className={"uppercase color-light"} ref={titleRef}></h1>
                            </div>
                            {page.subheadline_unterseite != '' && page.cooperation_logo ?
                                <Link to={page.cooperation_link} target={"_blank"} className="subheadline">
                                    <h6>{page.subheadline_unterseite}</h6>
                                    <GatsbyImage image={page.cooperation_logo?.asset?.gatsbyImageData}></GatsbyImage>
                                </Link>
                                : <></>}
                        </div>
                        <div className="description">
                            <MyPortableText value={page._rawText}></MyPortableText>
                        </div>
                        <Link to={localeLink("/", location)} className={"hover-moveup backlink underline"}>{page.back}</Link>

                    </div>
                </div>
                {page._rawCtatext ? <CallToAction text={page._rawCtatext} link1={page.ctalink1} link2={page.ctalink2} linktext1={page.ctalink1text} linktext2={page.ctalink2text}></CallToAction> : <></>}
            </Layout>
        </div>
    )
}

export default localize(AngebotPage)
